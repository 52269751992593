const problemSet = {
  name: '问题集',
  id: '问题集ID',
  title: '标题',
  description: '描述',
  total: '问题数量',
  solved: '已解决',
  tag: 'Tag',
  deleteWarning: '此操作不可撤销，确定删除此问题集？',
  modifySuggestion: '请输入问题标题（必填）和描述',
  messages: {
    problemFileError: '问题文件异常！',
    removeWarning: '此操作不可撤销，确定移除此问题？',
  },
};

export default problemSet;
