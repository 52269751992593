import common from './common';
import home from './home';
import solver from './solver';
import tool from './tool';
import statistic from './statistic';
import signup from './signup';
import problem from './problem';
import problemSet from './problemSet';
import optimization from './optimization';
import result from './result';
import profile from './profile';
import competition from './competition';

const dictionary = {
  common,
  home,
  solver,
  tool,
  statistic,
  signup,
  problem,
  problemSet,
  optimization,
  result,
  profile,
  competition,
};

export default dictionary;
