const optimization = {
  name: '请求',
  detailOneBreadcrumb: '详情(基于问题)',
  detailTwoBreadcrumb: '详情(基于问题集)',
  detailDiffBreadcrumb: '基于问题集对比',
  compare: '对比',
  compareSelectErrorWarning: '请选择两个基于同一个问题的请求进行对比',
  id: '请求ID',
  solverVersion: '求解器版本号',
  problemSetId: '问题集ID',
  state: '状态',
  type: '类型',
  fromSubmittedDate: '提交开始',
  toSubmittedDate: '提交结束',
  submittedTime: '提交时间',
  startTime: '计算开始',
  endTime: '计算结束',
  failureReason: '失败原因',
  submitter: '发起人',
  steps: {
    label1: '选择求解器',
    label2: '填写表单',
  },
  parameter: '参数',
};

export default optimization;
