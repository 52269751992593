const result = {
  name: '结果',
  id: '结果ID',
  jobId: 'JOB ID',
  fromCreateTime: '开始时间',
  toCreateTime: '结束时间',
  detail: '详情',
  messages: {
    cannotCancel: '任务已结束，无法取消或中止!',
  },
  share: {
    linkText: '分享',
    title: '结果日志分享',
    copied: '已复制',
    copy: '复制',
  },
};

export default result;
