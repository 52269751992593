const solver = {
  module: '求解器',
  solverList: '求解器列表',
  groupBy: '分组',
  problemType: '问题类型',
  solver: '求解器',
  filters: '过滤条件',
  expandAll: '展开所有',
  collapseAll: '折叠所有',
  inputType: '输入类型',
  submissionForm: '表单',
  uploadProblem: '上传问题文件',
  useCommonProblem: '使用公共问题',
  commonProblemFile: '公共问题',
  selectVersion: "求解器版本",
  machineGroup: "集群",
  machineGroupTitle: "可用集群",
};

export default solver;
