const signup = {
  signUpAgreement: `我已阅读并接受`,
  signUpAgreementLink: ` 用户使用协议`,
  signUpAgreementAnd: ` 和`,
  signUpAgreementLink2: ` 隐私政策。`,
  signUpGroup1: '通过用户名/邮箱登录账号',
  signUpGroup2: '完善个人信息',
  usernameFormat:
    '用户名命名规则：1、请使用英文字母（A-Z，a-z）、数字（0-9）或者连接符(-)、下划线(_)，并且以英文字母开头；2、总长度控制在 5-128 个字符，3、大小写不敏感，大写将自动转换为小写。比如，Admin和admin是同一用户。',
  registrationForm: '注册表单',
  betterExperience:
    '升级您的在线优化体验——免费注册后您可享受个人问题集管理功能，便捷地管理问题集与查看历史结果',
  activeSuccess: `激活成功，请使用您的账户或者邮箱登录系统。`,
  activeError: `激活失败，请检查您的邮箱。`,
};

export default signup;
