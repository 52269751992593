const type = 'Result';

const result = (builder) => ({
  getResults: builder.query({
    query: (data) => ({
      url: 'optimization/opt_result/query',
      method: 'POST',
      data: {
        project_name: `default`,
        ...data,
      },
    }),
    providesTags: (result) =>
      result
        ? [...result?.data.map(({ id }) => ({ type, id })), { type, id: 'LIST' }]
        : [{ type, id: 'LIST' }],
  }),

  getResult: builder.query({
    query: (id) => ({
      url: `/optimization/result/${id}`,
      method: 'GET',
    }),
    providesTags: (result, error, id) => [{ type, id }],
  }),
  cancelResult: builder.mutation({
    query: (id) => ({
      url: '/optimization/cancel',
      method: 'POST',
      data: {
        resultId: id,
      },
    }),
    invalidatesTags: (result, error, id) => [
      { type, id },
      { type, id: 'LIST' },
    ],
  }),
});

export default result;
