const profile = {
  name: '用户设置',
  updateProfile: '更新',
  changePassword: '修改密码',
  oldPassword: '旧密码',
  newPassword: '新密码',
  changeEmail: '修改邮箱',
  changeEmailDesc:
    '请输入您的新邮箱，点击发送验证码，然后输入您新邮箱中收到的验证码',
  activity: '活动',
  deleteAccount: '删除账号',
  accountInfo: '账号',
  createTime: '注册时间',
  updateTime: '上次更新时间',
  home: '个人主页',
  account: '账号',
  accountTooltip: '登录用户名注册后无法二次修改',
  tooltips: {
    deleteAccount: {
      title: '您确定执行此操作？',
      message:
        '我们将立即删除您的所有问题文件，以及您的所有问题集、历史请求和结果。并且您的用户名将可供 COAP 上的任何人使用',
    },
  },
  username: '您的用户名或邮箱',
  password: '请输入确认密码',
};

export default profile;
