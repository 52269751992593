const result = {
  name: 'Result',
  id: 'Result ID',
  jobId: 'JOB ID',
  fromCreateTime: 'From Create Time',
  toCreateTime: 'To Create Time',
  detail: 'Detail',
  messages: {
    cannotCancel: 'This optimization task has ended and cannot be canceled!',
  },
  share: {
    linkText: 'Share',
    title: 'Share your result log',
    copied: 'Copied',
    copy: 'Copy',
  },
};

export default result;
