const competition = {
  name: 'Competitons',
  description:
    'Challenge yourself with real-world intelligent decision-making problems and grow your analytics and modeling skills by competing with top talents.',
  fields: {
    title: 'Title',
    subject: 'Subject',
    status: 'Status',
    sponsoredBy: 'Sponsored By',
    startDate: 'Starts at',
    endDate: 'Ends at',
    prize: 'Prize',
    deadline: 'Deadline',
  },
  status: {
    ongoing: 'Ongoing',
    ended: 'Ended',
  },
};

export default competition;