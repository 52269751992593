const problem = {
  name: '问题',
  id: '问题ID',
  addToPool: '添加到处理池',
  backToPrevLevel: '返回上层',
  root: '全部文件',
  fileUpload: '文件上传',
  largeFileUpload: '大文件上传',
  newDirection: '新建目录',
  directoryName: '目录名',
  directoryNameConvention: '目录命名规范',
  directoryNameConvention1: `1、请使用英文字母（A-Z，a-z）、数字（0-9）或者连接符(-)，并且以英文字母开头；`,
  directoryNameConvention2: `2、目录名长度不多于 128 个字符。`,
  attributes: '问题属性',
  testRecord: '求解记录',
  fileName: '文件名',
  problemName: '问题名',
  path: '路径',
  size: '大小',
  analyzing: '分析中',
  deleted: '已删除',
  poolSelectedItems: '已选择',
  poolNewProblemSet: '新增问题集',
  poolAddToExit: '添加到问题集',
  poolSelectedInfo: '请选择添加到的问题集',
  dragFileTitle: `将文件拖到此处，或单击直接上传`,
  baseFileLimitInfo: `最多可以同时上传2000个文件，并且单个文件的大小不能超过500M。在文件上传过程中请勿刷新或关闭页面，否则上传任务将被中断并且列表将被删除。`,
  largeFileLimitInfo: `一次只能上传一个文件，并且文件大小不能超过{{ size }} 。 在文件上传过程中，请勿刷新或关闭页面，否则上传任务将被中断并且列表将被清除。`,
  disabledFileWarning: `以下文件不允许上传，请检查文件格式和大小等`,
  exception: '解析失败',
  messages: {
    gettingSparkMD5: '文件较大，正在校验中... 请耐心等待！',
  },
};

export default problem;
