const solver = {
  module: 'Solver',
  solverList: 'Solver List',
  groupBy: 'Group By',
  problemType: 'Problem Type',
  solver: 'Solver',
  filters: 'Filters',
  expandAll: 'Expand All',
  collapseAll: 'Collapse All',
  inputType: 'Input Type',
  submissionForm: 'Submission Form',
  uploadProblem: 'Upload Problem File',
  useCommonProblem: 'Use Common Problem',
  commonProblemFile: 'Common Problem File',
  selectVersion: "Solver Version",
  machineGroup: "Machine Group",
  machineGroupTitle: "Available Machine Groups",
};

export default solver;
