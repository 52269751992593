const tool = {
  module: 'Tool',
  subtitle1:
    'Enter the job number and the password of the job you wish to dequeue/view, You can leave these blank if viewing the queue.',
  subtitle2: 'You can leave these blank if viewing the queue.',
  form: {
    jobNumber: 'Job Number',
    jobPassword: 'Job Password',
    viewQueue: 'View Job Queue',
    viewResult: 'View Job Results',
    dequeue: 'Dequeue Job',
  },
  status: {
    title: 'Status',
    waiting: 'Waiting',
    processing: 'Processing',
    finished: 'Completed',
    failed: 'Failed',
    cancel: 'Cancelled',
    deleted: 'Deleted',
  },
};

export default tool;
