import React from 'react';
import { Navigate } from 'react-router-dom';

import { loadable } from 'components/Loading';

const NotFound = loadable((props) => import('components/ErrorPage/NotFound'));
const ActiveRedirect = loadable((props) => import('components/ErrorPage/ActiveRedirect'));

// Home
const Home = loadable((props) => import('features/Home'));

// Solver
const SolverLayout = loadable((props) => import('features/Solvers'));
const SolverList = loadable((props) => import('features/Solvers/List'));
const SolverDetail = loadable((props) => import('features/Solvers/Detail'));

// Tools
const Tools = loadable((props) => import('features/Tools'));

// Sign up
const SignUp = loadable((props) => import('features/SignUp'));

// Result
const ResultLayout = loadable((props) => import('features/Results'));
const ResultQueue = loadable((props) => import('features/Results/Queue'));
const ResultDetail = loadable((props) => import('features/Results/Detail'));

// Statistics
const Statistics = loadable((props) => import('features/Statistics'));

// Competitions
const CompetitionsList = loadable((props) => import('features/Competitions/List'));
const CompetitionsDetail = loadable((props) => import('features/Competitions/Detail'));

// 控制台
const PrivateLayout = loadable((props) =>
  import('components/Layout/PrivateLayout')
);
const Problem = loadable((props) => import('features/Console/Problem'));
const ProblemDetail = loadable((props) =>
  import('features/Console/Problem/Detail')
);
const ProblemSet = loadable((props) => import('features/Console/ProblemSet'));
const ProblemSetDetail = loadable((props) =>
  import('features/Console/ProblemSet/Detail')
);
const Optimization = loadable((props) =>
  import('features/Console/Optimization')
);
const OptimizationDetailByProblem = loadable((props) =>
  import('features/Console/Optimization/Detail/DetailByProblem')
);
const OptimizationDetailByProblems = loadable((props) =>
  import('features/Console/Optimization/Detail/DetailByProblems')
);
const Result = loadable((props) => import('features/Console/Result'));
const Profile = loadable((props) => import('features/Console/Profile'));
const ProfileHome = loadable((props) =>
  import('features/Console/Profile/Home')
);
const ProfileAccount = loadable((props) =>
  import('features/Console/Profile/Account')
);

const routes = [
  { index: true, element: <Home /> },
  {
    path: 'solvers',
    element: <SolverLayout />,
    children: [
      { index: true, element: <SolverList /> },
      { path: 'list', element: <SolverList /> },
      { path: 'detail/:id', element: <SolverDetail /> },
    ],
  },
  {
    path: 'competitions',
    children: [
      { index: true, element: <CompetitionsList /> },
      { path: 'list', element: <CompetitionsList /> },
      { path: ':id', element: <CompetitionsDetail /> },
    ],
  },
  { path: 'tools', element: <Tools /> },
  {
    path: 'results',
    element: <ResultLayout />,
    children: [
      { index: true, element: <ResultQueue /> },
      { path: 'queue', element: <ResultQueue /> },
      { path: 'detail/:jobId/:password', element: <ResultDetail /> },
      { path: 'share/:uniqueCode', element: <ResultDetail /> },
    ],
  },
  { path: 'statistics', element: <Statistics /> },
  { path: 'signup', element: <SignUp /> },
  {
    path: 'dev',
    element: <PrivateLayout />,
    children: [
      { index: true, element: <Navigate to="/dev/problem" replace={true} /> },
      {
        path: 'problem',
        children: [
          { index: true, element: <Problem /> },
          { path: ':id', element: <ProblemDetail /> },
        ],
      },
      {
        path: 'set',
        children: [
          { index: true, element: <ProblemSet /> },
          { path: ':id', element: <ProblemSetDetail /> },
        ],
      },
      {
        path: 'optimization',
        children: [
          { index: true, element: <Optimization /> },
          { path: ':id', element: <OptimizationDetailByProblem /> },
          { path: 'set/:id', element: <OptimizationDetailByProblems /> },
        ],
      },
      { path: 'result', element: <Result /> },
      {
        path: 'profile',
        element: <Profile />,
        children: [
          { index: true, element: <ProfileHome /> },
          { path: ':tab', element: <ProfileAccount /> },
        ],
      },
    ],
  },
  { path: 'redirect', element: <ActiveRedirect /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
