const providesTag = 'Input';

const inputServices = {
  queryList: {
    query: () => ({ url: '/home/inputs/', method: 'GET' }),
    providesTags: (result) =>
      result
        ? [...result.map(({ id }) => ({ type: providesTag, id })), { type: providesTag, id: 'LIST' }]
        : [{ type: providesTag, id: 'LIST' }],
  },
  query: {
    query: (id) => ({ url: `/home/input/${id}`, method: 'GET' }),
    providesTags: (result, error, id) => [{ type: providesTag, id }],
  },
};

export default inputServices;
