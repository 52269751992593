const type = 'Problem Set';

const problemSet = (builder) => ({
  getProblemSets: builder.query({
    query: (data) => ({
      url: '/problems/query',
      method: 'POST',
      data: {
        project_name: `default`,
        ...data,
      },
    }),
    providesTags: (result) =>
      result
        ? [...result?.data.map(({ id }) => ({ type, id })), { type, id: 'LIST' }]
        : [{ type, id: 'LIST' }],
  }),
  addProblemSet: builder.mutation({
    query: (data) => ({
      url: '/problems/add',
      method: 'POST',
      data: {
        project_name: `default`,
        ...data,
      },
    }),
    invalidatesTags: [{ type, id: 'LIST' }],
  }),
  deleteProblemSet: builder.mutation({
    query: (id) => ({
      url: `/problems/delete/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, id) => [
      { type, id },
      { type, id: 'LIST' },
    ],
  }),
  getProblemSet: builder.query({
    query: (id) => ({
      url: `/problems/${id}`,
      method: 'GET',
    }),
    providesTags: (result, error, id) => [{ type, id }],
  }),
  // 更新问题集头信息
  updateProblemSet: builder.mutation({
    query: (data) => ({
      url: `/problems`,
      method: 'PATCH',
      data,
    }),
    invalidatesTags: (result, error, { id }) => [{ type, id }],
  }),
  // 移除问题集下问题
  deleteProblemSetDetail: builder.mutation({
    query: (data) => ({
      url: `/problems/removeProblems`,
      method: 'DELETE',
      data,
    }),
    invalidatesTags: (result, error, { id }) => [{ type, id }],
  }),
  // 问题集下添加问题
  updateProblemSetDetail: builder.mutation({
    query: (data) => ({
      url: `/problems/addProblems`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (result, error, { id }) => [{ type, id }],
  }),
});

export default problemSet;
