const problem = {
  name: 'Problem',
  id: 'Problem ID',
  addToPool: 'Add to Pool',
  backToPrevLevel: 'Back to previous level',
  root: 'All Files',
  fileUpload: 'File Upload',
  largeFileUpload: 'Large File Upload',
  newDirection: 'New directory',
  directoryName: 'Directory name',
  directoryNameConvention: 'Directory naming convention',
  directoryNameConvention1: `1. A directory only contains alphanumeric characters (letters A-Z, numbers 0-9), hyphen (-);`,
  directoryNameConvention2: `2. A directory cannot be longer than 128 characters.`,
  attributes: 'Attributes',
  testRecord: 'Test Record',
  fileName: 'File Name',
  problemName: 'Problem Name',
  path: 'Path',
  size: 'Size',
  analyzing: 'Analyzing',
  deleted: 'Deleted',
  poolSelectedItems: 'Selected items',
  poolNewProblemSet: 'New Problem Set',
  poolAddToExit: 'Add to Existing Problem Set',
  poolSelectedInfo: 'Please Select a Problem Set',
  dragFileTitle: `Drag files here, or click to upload directly`,
  baseFileLimitInfo: `Problem files of size less than 500MB could be uploaded simultaneously. DO NOT refresh or close it until the upload task is finished.`,
  largeFileLimitInfo: `Large problem file of size less than {{ size }}  could be uploaded sequentially. DO NOT refresh or close it until the upload task is finished.`,
  disabledFileWarning: `The following files are not allowed to upload, please check the file format and size.`,
  exception: 'Exception',
  messages: {
    gettingSparkMD5: 'The file is large and is being verified, please wait!',
  },
};

export default problem;
