const type = 'Auth';

const auth = (builder) => ({
  // 登录
  login: builder.mutation({
    query: (data) => ({
      url: `/user/login`,
      method: 'POST',
      data: { 'g-recaptcha-response': 'string', ...data },
    }),
  }),
  // 退出
  logout: builder.mutation({
    query: () => ({
      url: `/user/logout`,
      method: 'DELETE',
    }),
  }),
  // 获取登录用户信息
  getAccount: builder.query({
    query: () => ({
      url: `/user/account`,
      method: 'GET',
    }),
    providesTags: [{ type, id: 'CURRENT' }],
  }),
  // 注册
  signup: builder.mutation({
    query: (data) => ({
      url: `/user/register`,
      method: 'POST',
      data,
    }),
  }),
  // 发送重置密码验证码
  sendVerificationCode: builder.mutation({
    query: (data) => ({
      url: `/user/reset-password-code`,
      method: 'POST',
      data,
    }),
  }),
  // 重置密码
  resetPassword: builder.mutation({
    query: (data) => ({
      url: `/user/resetPassword`,
      method: 'PATCH',
      data,
    }),
    invalidatesTags: [{ type, id: 'CURRENT' }],
  }),
  // 更新个人信息
  updateProfile: builder.mutation({
    query: (data) => ({
      url: `/user/update`,
      method: 'PATCH',
      data,
    }),
    invalidatesTags: [{ type, id: 'CURRENT' }],
  }),
  // 修改密码
  updatePassword: builder.mutation({
    query: (data) => ({
      url: `/user/updatePassword`,
      method: 'PATCH',
      data,
    }),
    invalidatesTags: [{ type, id: 'CURRENT' }],
  }),
  // 修改邮箱
  updateEmail: builder.mutation({
    query: (data) => ({
      url: `/user/modify-email-address`,
      method: 'PATCH',
      data,
    }),
    invalidatesTags: [{ type, id: 'CURRENT' }],
  }),
  // 修改邮箱发送验证吗
  sendEmailVerificationCode: builder.mutation({
    query: (data) => ({
      url: `/user/modify-email-code`,
      method: 'POST',
      data,
    }),
  }),
  // 删除账号
  deleteAccount: builder.mutation({
    query: (password) => ({
      url: `/user/cancel`,
      method: 'PATCH',
      params: { password }
    }),
  }),
});

export default auth;
