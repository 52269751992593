import React from "react";
import baseLoadable from "@loadable/component";

import FullScreenLoading from "./FullScreenLoading";

function loadable(func) {
  return baseLoadable(func, { fallback: <FullScreenLoading /> });
}

export { loadable, FullScreenLoading };
