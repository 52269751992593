import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import enLocale from 'date-fns/locale/en-US';
import zhLocale from 'date-fns/locale/zh-CN';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@mui/material/CssBaseline';
import { useDispatch, useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';

import { coapApi } from 'services/coap'
import { authActions } from 'store/authSlice'
import lightTheme from 'theme/light';
import darkTheme from 'theme/dark';
import { LanguageContext, LanguageProvider } from './Language';
import routes from './routes'

// date-fns locale data
const localeMap = {
  'en-us': enLocale,
  'zh-cn': zhLocale,
};

// Material UI locale data
const MUILocaleMap = {
  'en-us': 'enUS',
  'zh-cn': 'zhCN',
};

// 主题
const themeMap = {
  light: lightTheme,
  dark: darkTheme,
};

function App() {
  const dispatch = useDispatch();
  const { theme } = useSelector((state) => state.common);
  const languageContext = React.useContext(LanguageContext);
  const locale = languageContext.language;
  const app = useRoutes(routes);
  const initialized = useSelector((state) => state.auth.initialized);

  const {
    data: account,
    isLoading,
    error,
  } = coapApi.useGetAccountQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (account) {
      dispatch(authActions.setAccount(account));
    }
  }, [account, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(authActions.redirectLogin());
    }
  }, [error, dispatch]);

  return (
    <ThemeProvider theme={themeMap[theme]}>
      <ThemeProvider
        theme={(outerTheme) =>
          createTheme(outerTheme, locales[MUILocaleMap[locale] || 'enUS'])
        }
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={localeMap[locale]}
        >
          <CssBaseline />
          <SnackbarProvider maxSnack={3}>
            {app}
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </ThemeProvider>
  );
}

const LanguageApp = () => {
  return (
    <LanguageProvider>
      <App />
    </LanguageProvider>
  );
};

export default LanguageApp;
