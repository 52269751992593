const common = {
  home: 'home',
  solvers: 'solvers',
  tools: 'tools',
  statistics: 'statistics',
  console: 'Console',
  footer: {
    terms: 'TERMS OF USE',
    qa: 'QUESTIONS AND COMMENTS',
    EULA: `EULA`,
    privacyPolicy: `Privacy Policy`,
  },
  form: {
    submit: 'Submit',
    reset: 'Reset',
    cancel: 'Cancel',
    refresh: 'Refresh',
    close: 'Close',
    sendCode: 'Send Code',
    resend: 'Resend',
    ok: 'Ok',
    delete: 'Delete',
    clear: 'Clear',
    view: 'View',
    add: 'Add',
    search: 'Search',
    remove: 'Remove',
    back: 'Back',
    upload: 'Upload',
    filters: 'Filters',
    done: 'Done',
    edit: 'Edit',
    save: 'Save',
  },
  ok: 'Ok',
  'detail-introduction': 'Detailed introduction',
  backHome: 'Back to Home',
  positionOption1: 'Academic-researcher',
  positionOption2: 'Academic-student',
  positionOption3: 'OR algorithm engineer',
  positionOption4: 'Business analyst',
  positionOption5: 'Data scientist',
  positionOption6: 'IT engineer',
  industryOption1: 'Education',
  industryOption2: 'Manufacturing',
  industryOption3: 'Financial service',
  industryOption4: 'Insurance',
  industryOption5: 'Media & Entertainment',
  industryOption6: 'Energy',
  industryOption7: 'Government',
  industryOption8: 'Healthcare',
  industryOption9: 'Logistics & Transportation',
  industryOption10: 'Aviation',
  industryOption11: 'Software',
  industryOption12: 'Retail',
  industryOption13: 'Telecommunications',
  others: 'Others',
  fullname: 'Fullname',
  age: 'Age',
  title: 'Title',
  company: 'Company',
  address: 'Address',
  account: 'User name',
  email: 'Email Address',
  password: 'Password',
  newPassword: 'New Password',
  confirmPassword: 'Re-enter password',
  name: 'Name',
  nickname: 'Nickname',
  industry: 'Industry',
  institute: 'Company/Education Institute',
  position: 'Position',
  phone: 'Phone',
  region: 'Country or region',
  city: 'City',
  reasons: 'Reasons for application & the scenarios that you need COAP',
  signin: 'Sign In',
  signout: 'Sign Out',
  signup: 'Sign Up',
  verificationCode: 'Verification Code',
  forgetPassword: 'Forget Password',
  irreversibleActionWarn: 'This operation is irreversible. Are you sure?',
  columns: {
    action: 'Action',
    id: 'ID',
    creator: 'Creator',
    createTime: 'Create Time',
    updater: 'Updater',
    updateTime: 'Update Time',
  },
  empty: 'Empty',
  autoRedirect:
    'It will automatically redirect to the home page after {{ count }} seconds, ',
  redirectImmediately: 'go now',
  messages: {
    dragValidateInfo: 'Hold down the slider and drag to the far right',
    dragValidateSuccess: 'Verification succeeded',
    dragValidateRequired: 'Please drag the slider to verify!',
    response: {
      status504: 'Network timeout(504)，please try again later！',
    },
  },
};

export default common;
