import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // 主题
  theme: 'light',

  // 语种
  locale: 'zhCN',

  // 控制台侧边栏展开
  opened: true,

  // 问题池：问题 Id 数组
  problemPool: [],
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    // 切换主题
    changeTheme: (state, action) => {
      state.theme = action.payload;
    },
    // 切换语种
    changeLocale: (state, action) => {
      state.locale = action.payload;
    },
    // 切换侧边栏展开状态
    changeOpenMenu: (state, action) => {
      state.opened = action.payload;
    },
    // 修改问题池
    changePool: (state, action) => {
      state.problemPool = action.payload;
    },
  },
});

export const { changeTheme, changeLocale, changeOpenMenu, changePool } =
  commonSlice.actions;

export default commonSlice.reducer;
