import { createSlice } from '@reduxjs/toolkit';
import Cookie from 'js-cookie';

const initialState = {
  initialized: false,
  user: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccount: (state, action) => {
      state.user = action.payload;
      state.initialized = true;
    },
    redirectLogin: (state, action) => {
      state.initialized = true;
      state.user = null;
      Cookie.remove('Authorization');
    },
  },
});

export const authActions = {
  ...authSlice.actions,
};

export default authSlice.reducer;
