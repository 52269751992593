const optimization = {
  name: 'Optimization',
  detailOneBreadcrumb: 'Detail(Based on Problem)',
  detailTwoBreadcrumb: 'Detail(Based on Problem Set)',
  detailDiffBreadcrumb: 'Comparison with problem set',
  compare: 'Diff',
  compareSelectErrorWarning:
    'Please select two requests based on the same problem set for comparison',
  id: 'Request ID',
  solverVersion: 'Solver Version',
  problemSetId: 'Problem Set ID',
  state: 'Status',
  type: 'Type',
  fromSubmittedDate: 'From Submitted Date',
  toSubmittedDate: 'To Submitted Date',
  submittedTime: 'Submitted Time',
  startTime: 'Start Time',
  endTime: 'End Time',
  failureReason: 'Failure Reason',
  submitter: 'Submitter',
  steps: {
    label1: 'Select Solver',
    label2: 'Fill the Form',
  },
  parameter: 'Parameter',
};

export default optimization;
