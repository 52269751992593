const problemSet = {
  name: 'Problem Set',
  id: 'Problem Set ID',
  title: 'Title',
  description: 'Description',
  total: 'Number of Problems',
  solved: 'Solved',
  tag: 'Tag',
  deleteWarning:
    'This operation is irreversible and you are sure to delete the problem set ?',
  modifySuggestion: 'Please enter a problem set title (required) and comments',
  messages: {
    problemFileError: 'Problem file error!',
    removeWarning: 'This operation is irreversible and you are sure to remove the problem?',
  },
};

export default problemSet;
