const name = '';
const tokenStorageKey = 'gAccessToken';
const baseURL = '/api/copt/v1/';

const config = {
  name,
  tokenStorageKey,
  baseURL,
};

export default config;
