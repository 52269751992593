const providesTag = 'SolverVersion';

const solverVersionServices = {
  queryList: {
    query: (request) => ({
      url: '/home/solverVersions/query',
      method: 'POST',
      data: {
        solverCode: request?.solverCode ?? "",
        solverVersion: request?.solverVersion ?? "",
        input: request?.input ?? "",
      },
    }),
    providesTags: (result) =>
      result
        ? [...result.map(({ id }) => ({ type: providesTag, id })), { type: providesTag, id: 'LIST' }]
        : [{ type: providesTag, id: 'LIST' }],
  },
  query: {
    query: (id) => ({ url: `/home/solverVersionInfo/${id}`, method: 'GET' }),
    providesTags: (result, error, id) => [{ type: providesTag, id }],
  },
};

export default solverVersionServices;
