const type = 'problem';

const problem = (builder) => ({
  getFiles: builder.query({
    query: (path) => ({
      url: '/file/special_folder',
      method: 'POST',
      data: { path },
    }),
  }),
  getFile: builder.query({
    query: (id) => ({
      url: `/file/${id}`,
      method: 'GET',
    }),
  }),
  createFolder: builder.mutation({
    query: ({ name, parentId }) => ({
      url: '/file/create_folder',
      method: 'POST',
      data: { file_name: name, parent_id: parentId },
    }),
  }),
  batchDeleteFiles: builder.mutation({
    query: (data) => ({
      url: '/file',
      method: 'DELETE',
      data,
    }),
  }),
  // 普通文件上传
  uploadProblemFile: builder.mutation({
    query: (data) => ({
      url: '/file/upload_regular_file',
      method: 'POST',
      data,
    }),
    invalidatesTags: [{ type, id: 'LIST' }],
  }),
  getProblem: builder.query({
    query: (id) => ({
      url: `/problem?id=${id}`,
      method: 'GET',
    }),
  }),
  // 获取基于指定问题的结果
  getProblemResults: builder.query({
    query: (data) => ({
      url: 'optimization/opt_result/query',
      method: 'POST',
      data: {
        project_name: `default`,
        id: null,
        job_id: null,
        name: null,
        optimization_id: null,
        problem_set_id: null,
        request_id: null,
        solver_version: null,
        create_dtt: [null, null],
        ...data,
      },
    }),
  }),
});

export default problem;
