import React, { createContext, useContext, useState } from 'react';

import { defaultLocale, dictionaryList } from '../languages';

// create the language context with default selected language
export const LanguageContext = createContext({
  language: defaultLocale,
  dictionary: dictionaryList[defaultLocale],
});

// it provides the language context to app
export function LanguageProvider(props) {
  const languageContext = useContext(LanguageContext);
  const [language, setLanguage] = useState(languageContext.language);
  const [dictionary, setDictionary] = useState(languageContext.dictionary);

  const provider = {
    language,
    dictionary,
    setLanguage: (locale) => {
      // setLanguage(locale); // it will update the language in state
      // setDictionary(dictionaryList[locale]);
      window?.localStorage?.setItem('locale', locale);
      // 通过重新加载网页的方式解决 api 重新调用问题
      window.location.reload();
    },
  };

  return (
    <LanguageContext.Provider value={provider}>
      {props.children}
    </LanguageContext.Provider>
  );
}
