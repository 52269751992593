const generateBasicEndpoints = (basePaths, providesTag) => ({
  queryList: {
    query: () => ({ url: basePaths[0], method: 'GET' }),
    providesTags: (result) =>
      result
        ? [...result.map(({ id }) => ({ type: providesTag, id })), { type: providesTag, id: 'LIST' }]
        : [{ type: providesTag, id: 'LIST' }],
  },
  update: {
    query: ({ id, ...data }) => ({
      url: `${basePaths[1]}`,
      method: 'POST',
      body: { id: Number(id), ...data },
    }),
    invalidatesTags: (result, error, { id }) => [{ type: providesTag, id }],
  },
  batchDelete: {
    query: (ids) => ({
      url: `${basePaths[2]}`,
      method: 'DELETE',
      body: { ids },
    }),
    invalidatesTags: (result, error, ids) => ids.map((id) => ({ type: providesTag, id })),
  },
  add: {
    query: (data) => ({
      url: `${basePaths[3]}`,
      method: 'POST',
      body: data,
    }),
    invalidatesTags: [{ type: providesTag, id: 'LIST' }],
  },
  query: {
    query: (id) => ({ url: `${basePaths[0]}/${id}`, method: 'GET' }),
    providesTags: (result, error, id) => [{ type: providesTag, id }],
  },
});

export default generateBasicEndpoints;
